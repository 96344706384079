/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html, body {  }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
 }

 ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #FFF; /* or add it to the track */
  }
  
  /* Add a thumb */::-webkit-scrollbar-thumb {
    background: #DEE2E6;
  }
